/* App.css */

body, html {
    height: 100%;
    margin: 0;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden; /* מונע גלילה גלובלית */
}

header, .header {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding-bottom: 10px;
    box-sizing: border-box;
    width: 100%;
    flex-shrink: 0; /* שומר על הגובה הקבוע של ההדר */
}

main {
    flex: 1; /* מאפשר למיין לתפוס את כל השטח הפנוי */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    overflow-y: auto; /* אפשר גלילה אנכית בתוך המיין */
}

footer, .footer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding-top: 10px;
    box-sizing: border-box;
    width: 100%;
    flex-shrink: 0; /* שומר על הגובה הקבוע של הפוטר */
}

@media (max-width: 600px) {
    .header img {
        height: 60px;
    }

    .footer {
        padding: 10px;
    }

    .footer .section-1,
    .footer .section-3 {
        flex: 1;
        text-align: center;
    }

    .footer .section-3 img {
        height: 30px;
    }
}
