html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden; /* חוסם גלילה גלובלית */
  }
  
   .app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
/*     background-image: url('../../assets/images/elitzurImage.png'); */
    background-size: cover; /* מכסה את כל האזור */
    background-position: center 10px; /* ממקם את התמונה במרכז */
    background-repeat: no-repeat; /* מונע חזרה של התמונה */
  } 
  
  .header, .footer {
    flex-shrink: 0; /* מונע מהם לשנות גובה */
    width: 100%;
  }
  
  .main-content {
    flex-grow: 1;
    overflow-y: auto; /* מאפשר גלילה אנכית רק במיין */
  }
  
  
