.dashboard {
  margin: 20px;
  font-family: 'Arial', sans-serif;
  direction: rtl;
  text-align: right;
}

.section-title {
  color: #ff5722;
  border-bottom: 2px solid #ff5722;
  display: inline-block;
  padding-bottom: 5px;
  margin-top: 20px;
}
.the-title {
  color: #ff5722;
  border-bottom: 2px solid #ff5722;
  display: block; /* או לא להוסיף כלום, ברירת מחדל היא block */
  text-align: center;
  padding-bottom: 5px;
  margin-top: 20px;
}


.form-container {
  max-width: 600px; /* גודל מקסימלי לטופס */
  margin: 0 auto; /* מרכז את הטופס */
  padding: 20px; /* מרווח פנימי */
  margin-top: 200px;
  border: 1px solid #ccc; /* מסגרת */
  border-radius: 8px; /* פינות מעוגלות */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* צל להבלטה */
  background-color: #f9f9f9; /* רקע */
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}


.form-group {
  display: flex;
  flex-direction: column;
}
.right-align {
  text-align: right; /* יישור טקסט לימין */
  display: block; /* הצגת התווית כחסום */
  margin-bottom: 5px; /* מרווח בין התוויות */
  direction: rtl; /* כיוון הטקסט מימין לשמאל */
}



label {
  font-weight: bold;
  color: #24201f;
}

input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  text-align: right;
}

button {
  align-self: center;
  padding: 10px 20px;
  border: none;
  background-color: #ff5722;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

button:hover {
  background-color: #e64a19;
}

.dialog-title {
  text-align: center;
}

.right-align {
  text-align: right;
}

/* נוספה עיצוב לכתובת, מייל וטלפון */
.form-container .form-group input[type="text"],
.form-container .form-group input[type="email"] {
  max-width: 100%;
}

/* סגנון ל-checkbox של הוספת שחקן נוסף */
.form-group input[type="checkbox"] {
  margin-left: 10px;
}

/* סגנון ל-select */
select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 5px;
}
