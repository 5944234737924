/* index.css */
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap'); */

@font-face {
  font-family: 'ploni';
  src: local('ploni'), url(./assets/fonts/ploni-ultralight-aaa.woff) format('woff');
  src: local('ploni'), url(./assets/fonts/ploni-ultralight-aaa.woff2) format('woff2');
  font-weight: 100;
}

@font-face {
  font-family: 'ploni';
  src: local('ploni'), url(./assets/fonts/ploni-light-aaa.woff) format('woff');
  src: local('ploni'), url(./assets/fonts/ploni-light-aaa.woff2) format('woff2');
  font-weight: 200;
}

@font-face {
  font-family: 'ploni';
  src: local('ploni'), url(./assets/fonts/ploni-regular-aaa.woff) format('woff');
  src: local('ploni'), url(./assets/fonts/ploni-regular-aaa.woff2) format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'ploni';
  src: local('ploni'), url(./assets/fonts/ploni-medium-aaa.woff) format('woff');
  src: local('ploni'), url(./assets/fonts/ploni-medium-aaa.woff2) format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'ploni';
  src: local('ploni'), url(./assets/fonts/ploni-demibold-aaa.woff) format('woff');
  src: local('ploni'), url(./assets/fonts/ploni-demibold-aaa.woff2) format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'ploni';
  src: local('ploni'), url(./assets/fonts/ploni-bold-aaa.woff) format('woff');
  src: local('ploni'), url(./assets/fonts/ploni-bold-aaa.woff2) format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'ploni';
  src: local('ploni'), url(./assets/fonts/ploni-ultrabold-aaa.woff) format('woff');
  src: local('ploni'), url(./assets/fonts/ploni-ultrabold-aaa.woff2) format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'ploni';
  src: local('ploni'), url(./assets/fonts/ploni-black-aaa.woff) format('woff');
  src: local('ploni'), url(./assets/fonts/ploni-black-aaa.woff2) format('woff2');
  font-weight: 800;
}

*{
  box-sizing: border-box;
}

p{
  font-family: 'ploni', 'Times New Roman', Times, serif;
}

html, body{
  height: 100%;
  margin: 0;
  padding: 0;
  background: #a2cf6e05;
}

#root, #root > main{
  height: 100%;
}

p{
  line-height: 1.2em !important;
}

.load{
  opacity: 0.8;
  pointer-events: none;
}

.credit{
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  flex-direction: column;
}

.credit img{
  width: 120px;
  height: auto;
  object-fit: contain;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset !important;
}

input[type="date" i] {
  color: black;    
}

.Mui-focused + div input[type="date" i],
.MuiFormLabel-filled + div input[type="date" i] {
  color: unset;    
}

.flex{
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
