.form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: flex;
    align-items: center;
  }
  
  .toggle-button {
    font-size: 0.8em; /* גודל טקסט קטן יותר */
    margin-right: 80px; /* מרווח בין הכפתור לטקסט */
    padding: 2px 5px; /* ריווח פנימי קטן */
    cursor: pointer; /* סמן מצביע */
  }

  .label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    direction: rtl; /* כיוון הטקסט מימין לשמאל */
  }
  
  label {
    font-weight: bold;
    color: #24201f;
    flex: 1;
    text-align: right; /* יישור לימין */
  }
  
  @media only screen and (min-width: 768px) { /* רוחב מינימלי של 768px עבור מחשבים נייחים וניידים */
    .move-up {
      margin-top: -1.2em;
      margin-right: 14.3em;
    }
  }
  /* עיצוב לניידים בלבד */
@media only screen and (max-width: 767px) { /* רוחב מקסימלי של 767px עבור ניידים */
  .move-up {
    margin-right:1.8em;
  }
}

  
  